body {
  /*background-color: #fbfbfb;*/
  background-color: #f0f0f0;
}

#ViewListingPort {
  max-width: 600px !important;
}

#NewListingPort {
  max-width: 800px !important;
}

a:link, a:visited {
  color: #153b2c;
}

a:hover, a:active {
  color: #82c6ab;
}