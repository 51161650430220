
.MuiBottomNavigation-root {
  height: 70;
}

/* Dark Grey #707070

/*.MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px #82C6AB;
}
.MuiInput-underline:before {
  border-bottom: 2px solid green;
}
*/

.ViewContainer {
  padding: 0 !important;
}

.cat {
  margin-top: 10px;
}

p {
  margin: 5px;
}